import { computed, observable, runInAction, action } from 'mobx'
import { actionAsync, asyncAction } from 'mobx-utils'
import Application from '@/libs/models'
import Web3 from 'web3'
import { Subscription, timer } from 'rxjs'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { Zero } from '@/constants'
import { FixedNumber } from '@ethersproject/bignumber'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export class WalletStore {
  ethereum: any = (window as any).ethereum

  app = new Application({ mainnet: true })
  @observable web3: Web3 | null = null
  @observable account = ''
  @observable bnbBalance = Zero
  @observable chainId = ''
  @observable loaded = false
  @observable navigationDrawer = false

  @observable isMetamask = false
  @observable mobileDialog = false

  constructor() {
    //
  }

  @action.bound setNavigationDrawer(val) {
    this.navigationDrawer = val
  }
  @action.bound setMobileDialog(val) {
    this.mobileDialog = val
  }

  @asyncAction *start() {
    try {
      this.app.start()
      this.isMetamask = this.app.isMetamask
      if (!this.isMetamask) {
        this.setMobileDialog(true)
        yield
      }
      if (yield this.app.getAddress()) {
        yield this.connect()
      }
    } catch (error) {
      this.setMobileDialog(true)
      console.error(error)
    }
    this.loaded = true
  }

  @asyncAction *connect() {
    loadingController.increaseRequest()
    try {
      const ok = yield this.app.login()
      this.web3 = this.app.web3
      if (ok) {
        this.web3 = this.app.web3
        this.chainId = yield this.web3!.eth.getChainId()
        this.account = yield this.app.getAddress()
        this.ethereum.removeListener('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.removeListener('chainChanged', this.ethereumConfigChanged)
        this.ethereum.once('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.once('chainChanged', this.ethereumConfigChanged)
      }
      return ok
    } catch (error) {
      error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  ethereumConfigChanged = () => {
    window.location.reload()
  }

  async switchNetwork(chainId: number) {
    if (this.connected) {
      try {
        await this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }]
        })
      } catch (error) {
        if (error.message.includes('wallet_addEthereumChain')) {
          if (chainId === 137) {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: 'Matic(Polygon) Mainnet',
                  nativeCurrency: {
                    name: 'Matic',
                    symbol: 'MATIC',
                    decimals: 18
                  },
                  rpcUrls: [
                    'https://rpc.maticvigil.com',
                    'https://rpc-mainnet.maticvigil.com',
                    'https://speedy-nodes-nyc.moralis.io/dbdd48ee7b4652ac0d44fd74/polygon/mainnet',
                    'https://rpc-mainnet.matic.network',
                    'wss://ws-mainnet.matic.network',
                    'https://rpc-mainnet.matic.quiknode.pro',
                    'https://matic-mainnet.chainstacklabs.com'
                  ],
                  blockExplorerUrls: ['https://polygonscan.com']
                }
              ]
            })
          } else if (chainId === 80001) {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: 'Matic(Polygon) Testnet Mumbai',
                  nativeCurrency: {
                    name: 'Matic',
                    symbol: 'tMATIC',
                    decimals: 18
                  },
                  rpcUrls: ['https://rpc-mumbai.maticvigil.com', 'wss://ws-mumbai.matic.today'],
                  blockExplorerUrls: ['https://mumbai.polygonscan.com/']
                }
              ]
            })
          }
        }
      }
    }
  }

  //#region computed
  @computed get connected() {
    return !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 3) + '...' + this.account.substr(this.account.length - 3)
  }

  @computed get isChainIdValid() {
    //TODO: change to mainnet
    return this.chainId && this.chainId + '' === Number(137).toString()
  }

  //#endregion
}

export const walletStore = new WalletStore()
