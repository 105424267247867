import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        primary: '#7ABFFF',
        accent: '#FFB156',
        gray1: '#e0e0e0'
      }
    }
  }
})
