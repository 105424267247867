<template>
  <v-app class="page-bg" style="overflow: hidden">
    <MobileDialog />
    <SnackBar />
    <GlobalLoading />
    <NavigationDrawer />
    <MobileNavigationBar v-if="$vuetify.breakpoint.smAndDown" />
    <NavigationBar v-else />
    <v-main :class="{ 'pa-4': $vuetify.breakpoint.smAndDown }">
      <Staking />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { walletStore } from './stores/wallet-store'

@Observer
@Component({
  components: {
    Staking: () => import('@/modules/staking/pages/staking.vue'),
    Footer: () => import('./Footer.vue'),
    NavigationDrawer: () => import('@/components/NavigationDrawer.vue'),
    NavigationBar: () => import('@/components/NavigationBar.vue'),
    MobileNavigationBar: () => import('@/components/MobileNavigationBar.vue'),
    SnackBar: () => import('@/components/snack-bar/snack-bar.vue'),
    GlobalLoading: () => import('@/components/global-loading/global-loading.vue'),
    MobileDialog: () => import('@/components/notify-mobile-dialog.vue'),
  },
})
export default class App extends Vue {
  mounted() {
    walletStore.start()
  }
}
</script>

<style>
.page-container {
  max-width: 1170px;
}
.page-bg {
  background: #0e3056 !important;
}
.v-application {
  font-family: 'Proxima Nova' !important;
}
.border-radius-8 {
  border-radius: 8px !important;
}
.border-radius-16 {
  border-radius: 16px !important;
}
.transparent-bg {
  background: transparent !important;
}
.btn-text {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 130%;
}
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.primary-border {
  border: 1px solid var(--v-primary-base) !important;
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #026899 !important;
}
.nav-btn-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
}

.fading-from-bottom {
  animation: fadeFromBottom ease-out 0.4s;
  -webkit-animation: fadeFromBottom ease-out 0.4s;
  -moz-animation: fadeFromBottom ease-out 0.4s;
  -o-animation: fadeFromBottom ease-out 0.4s;
  -ms-animation: fadeFromBottom ease-out 0.4s;
}

@keyframes fadeFromBottom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@700&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-BlackIt.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-BoldIt.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-SemiBold.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-SemiBoldIt.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-RegularIt.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-LightIt.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(./assets/fonts/proxima-soft/ProximaSoft-ThinIt.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
</style>
